import { Component, Input, OnInit } from '@angular/core';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { UtilityInfo } from '../utility-info.model';

@Component({
  selector: 'utility-info',
  templateUrl: './utility-info.component.html',
  styleUrls: ['./utility-info.component.scss']
})
export class UtilityInfoComponent implements OnInit {

  @Input() utility!: UtilityInfo;

  faDownload = faDownload;
  faGitHub = faGithub;

  constructor() { }

  ngOnInit(): void {
  }

}
