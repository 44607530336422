import { Component, Input } from '@angular/core';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { BuildInfo } from '../build-info.model';

@Component({
  selector: 'build-info',
  templateUrl: './build-info.component.html',
  styleUrls: ['./build-info.component.scss']
})
export class BuildInfoComponent {

  @Input() build!: BuildInfo;

  faDownload = faDownload;
  faGitHub = faGithub;

  ngOnInit(): void {
  }

}
