import { Component, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import Utils from '../common/utils';
import { GameInfo } from '../game-info.model';
import gamesJson from '../games.json';
import { SourcePortInfo } from '../sourceport-info.model';
import { DashboardItem } from '../dashboard-item.model';
import { Octokit } from '@octokit/rest';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import { NewsItem } from '../news-item.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  sourcePorts: SourcePortInfo[] = [];
  games: GameInfo[] = [];
  currentItem!: DashboardItem;
  currentItemGames: GameInfo[] = [];
  currentItemNews: NewsItem[] = [];
  dashboardItems: DashboardItem[] = [];
  filteredDashboardItems: DashboardItem[] = [];
  gamesText = "hello";
  newsText = "hello";
  releaseTitle = "hello";
  twitterUpdate = "";
  @ViewChild('codeModal') codeModal: any;
  gitHubToken: String = "";
  mode: string = "all";

  constructor(private modalService: NgbModal, @Inject(LOCALE_ID) private locale: string, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.gitHubToken = this.route.snapshot.paramMap.get("ghtoken")?.toString()!;
    this.games = gamesJson;
    this.sourcePorts = Utils.getSourcePorts(this.games);
    this.dashboardItems = Utils.getDashboardItems(this.games, this.sourcePorts);
    this.filteredDashboardItems = this.dashboardItems;
    this.gitHubActions();
  }

  private gitHubActions() {
    const octokit = new Octokit({
      auth: this.gitHubToken,
    });

    this.dashboardItems.forEach(item => {
      if (item.sourcePortGitHub.startsWith("https://github.com/")) {

        var owner = item.sourcePortGitHub.split('/').slice(-2)[0];
        var repo = item.sourcePortGitHub.split('/').slice(-2)[1];

        octokit.rest.repos.getLatestRelease({
          owner,
          repo,
        })
          .then(({ data }) => {

            var ghurl = "https://github.com/" + owner + "/" + repo;

            this.dashboardItems.forEach(item => {
              if (item.sourcePortGitHub == ghurl) {
                item.lastReleaseTag = data.tag_name;
                item.lastReleaseName = data.name!;
                item.lastReleaseURL = data.html_url;
                item.lastReleasePublished = new Date(data.published_at!);
                item.lastReleasePublished.setHours(0, 0, 0, 0);

                if (item.buildDate < item.lastReleasePublished) {
                  item.upToDate = "No"
                } else {
                  item.upToDate = "Yes"
                }

              }
            });
          })
          .catch(() => {
          });

        octokit.rest.repos.listTags({
          owner,
          repo,
        }).then(({ data }) => {

          let ref = data[0].commit.sha;

          octokit.rest.repos.getCommit({
            owner,
            repo,
            ref,
          }).then(({ data }) => {
            // console.log(JSON.stringify(data, null, 4));
          }).catch(() => {
            // console.log("tag with " + ref + " not foud")
          });
        })
          .catch(() => {

          });

        octokit.rest.repos.get({
          owner,
          repo,
        })
          .then(({ data }) => {
            var ghurl = "https://github.com/" + owner + "/" + repo;

            this.dashboardItems.forEach(item => {
              if (item.sourcePortGitHub == ghurl) {
                item.sourcePortGitHubLastPush = new Date(data.pushed_at);
                item.sourcePortGitHubLastPush.setHours(0, 0, 0, 0);
                if (item.lastReleasePublished == null && item.upToDate == null) {
                  if (item.buildDate < item.sourcePortGitHubLastPush) {
                    item.upToDate = "Maybe"
                  }
                }
              }
            });
          })
          .catch(() => {

          });
      }
    });
  }

  openModal(item: DashboardItem) {
    this.currentItem = item;
    this.currentItemGames = Utils.getGamesForSourcePort(this.games, this.currentItem.sourcePortStub);
    this.currentItemNews = [];
    this.twitterUpdate = "";

    var newsDate = new Date();

    this.currentItemGames.forEach(itemGame => {
      itemGame.lastUpdated = new Date();

      var newsItem = new NewsItem();
      newsItem.title = itemGame.gameTitle + " updated"
      newsItem.link = "https://macsourceports.com/game/" + itemGame.stubName;
      newsItem.date = new Date(newsDate);
      newsItem.content = "The build of " + item.sourcePortTitle + " for " + itemGame.gameTitle + " has been updated to version " + this.currentItem.lastReleaseTag + " of the project";
      newsItem.icon = itemGame.appIcon;

      this.twitterUpdate += "The build of " + item.sourcePortTitle + " for " + itemGame.gameTitle + " on MacSourcePorts.com has been updated to version " + this.currentItem.lastReleaseTag + " of the project";
      this.twitterUpdate += "\n\n" + "https://macsourceports.com/game/" + itemGame.stubName;
      this.twitterUpdate += "\n\n#macgaming #retrogaming #macOS #" + itemGame.stubName + " #" + item.sourcePortStub + " #gaming #apple #m1 #m2 #m3 #applesilicon\n\n"

      newsDate.setMinutes(newsDate.getMinutes() + 1);

      this.currentItemNews.push(newsItem);

      itemGame.sourcePorts.forEach(itemGameSourcePort => {
        itemGameSourcePort.games = undefined;
        if (itemGameSourcePort.sourcePortStub === this.currentItem.sourcePortStub) {
          itemGameSourcePort.builds.forEach(itemGameSourcePortBuild => {
            itemGameSourcePortBuild.version = this.currentItem.lastReleaseTag;
            if (this.currentItem.lastReleasePublished) {
              itemGameSourcePortBuild.buildDate = formatDate(this.currentItem.lastReleasePublished, 'MMMM d, yyyy', this.locale);;
            } else {
              itemGameSourcePortBuild.buildDate = formatDate(newsDate, 'MMMM d, yyyy', this.locale);;
            }
          })
        }
      })
    })

    this.gamesText = JSON.stringify(this.currentItemGames, null, 4);

    this.newsText = JSON.stringify(this.currentItemNews, null, 4);

    this.releaseTitle = this.currentItem.sourcePortTitle + " " + this.currentItem.lastReleaseTag

    // DEBUG
    // this.games.forEach(game => {
    //   game.addedToMacSourcePorts = new Date(game.addedToMacSourcePorts);
    //   game.lastUpdated = new Date(game.lastUpdated);
    //   game.sourcePorts.forEach(sourcePort => {
    //     sourcePort.games = undefined;
    //   })
    // })
    // this.modalText = JSON.stringify(this.games, null, 4);


    this.modalService.open(this.codeModal, { fullscreen: true });
  }

  onVersionChange(searchValue: any): void {

    this.currentItemNews = [];
    var newsDate = new Date();

    this.twitterUpdate = "";

    this.currentItemGames.forEach(itemGame => {

      var newsItem = new NewsItem();
      newsItem.title = itemGame.gameTitle + " updated"
      newsItem.link = "https://macsourceports.com/game/" + itemGame.stubName;
      newsItem.date = new Date(newsDate);
      newsItem.content = "The build of " + this.currentItem.sourcePortTitle + " for " + itemGame.gameTitle + " has been updated to version " + searchValue + " of the project";
      newsItem.icon = itemGame.appIcon;

      this.twitterUpdate += "The build of " + this.currentItem.sourcePortTitle + " for " + itemGame.gameTitle + " on MacSourcePorts.com has been updated to version " + searchValue + " of the project";
      this.twitterUpdate += "\n\n" + "https://macsourceports.com/game/" + itemGame.stubName;
      this.twitterUpdate += "\n\n#macgaming #retrogaming #macOS #" + itemGame.stubName + " #" + this.currentItem.sourcePortStub + " #gaming #apple #m1 #m2 #m3 #applesilicon\n\n"

      newsDate.setMinutes(newsDate.getMinutes() + 1);

      this.currentItemNews.push(newsItem);

      itemGame.sourcePorts.forEach(itemGameSourcePort => {
        if (itemGameSourcePort.sourcePortStub === this.currentItem.sourcePortStub) {
          itemGameSourcePort.builds.forEach(itemGameSourcePortBuild => {
            itemGameSourcePortBuild.version = searchValue;
          })
        }
      })
    })
    this.gamesText = JSON.stringify(this.currentItemGames, null, 4);
    this.newsText = JSON.stringify(this.currentItemNews, null, 4);
    this.releaseTitle = this.currentItem.sourcePortTitle + " " + searchValue
  }

  onChange(event: any) {
    this.games = gamesJson;

    if (this.mode == "all") {
      this.filteredDashboardItems = this.dashboardItems
    } else if (this.mode == "msp") {
      this.filteredDashboardItems = this.dashboardItems.filter(
        (item: DashboardItem) => item.MSPBuild == true
      )
    } else if (this.mode == "tpb") {
      this.filteredDashboardItems = this.dashboardItems.filter(
        (item: DashboardItem) => item.MSPBuild != true
      )
    }
  }


}
