import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { GameInfo } from "../game-info.model";

@Pipe({
    name: 'filterGames',
    pure: false
})
@Injectable()
export class FilterGamesPipe implements PipeTransform {
    transform(value: any, args?: any): GameInfo[] {
        if (!args)
            return value;
        return value.filter(
            (item: GameInfo) => item.gameTitle.toLowerCase().indexOf(args.toLowerCase()) > -1
        );
    }
}