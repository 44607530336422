import { Component, Input, OnInit } from '@angular/core';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faDownload, faFileContract, faStamp, faYinYang } from '@fortawesome/free-solid-svg-icons';
import { SourcePortInfo } from '../sourceport-info.model';

@Component({
  selector: 'sourceport-info',
  templateUrl: './sourceport-info.component.html',
  styleUrls: ['./sourceport-info.component.scss']
})
export class SourcePortInfoComponent implements OnInit {

  @Input() sourcePort!: SourcePortInfo;

  faDownload = faDownload;
  faQuestionCircle = faQuestionCircle;
  faFileContract = faFileContract;
  faStamp = faStamp;
  faGitHub = faGithub;
  faYinYang = faYinYang;

  constructor() { }

  ngOnInit(): void {
    this.sourcePort.games?.sort((a, b) => {
      return a.gameTitle.localeCompare(b.gameTitle);
    })
  }

}
