<div class="card bg-secondary">
    <div class="card-header">
        <div class="row">
            <div class="col-12 col-md-8 align-middle text-left">
                <h3><a style="text-decoration: none;" [routerLink]="['/utility', utility.stubName]"><img
                            *ngIf="utility.appIcon" src="{{utility.appIcon}}" width="64" height="64" class="gameicon" />
                        {{utility.utilityTitle}}</a></h3>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col">
                <p>
                    <strong>Developer:</strong>&nbsp;{{utility.developer}}<br>
                    <strong>Release Date:</strong>&nbsp;{{utility.releaseDate}}<br>
                </p>
                <p [innerHTML]="utility.description"></p>
            </div>
            <div class="col-auto">
                <img *ngIf="utility.screenshot" style="float: right;" src="{{utility.screenshot}}" width="400" />
            </div>
        </div>
        <div><img *ngIf="utility.appIcon" src="{{utility.appIcon}}" width="32" height="32" class="gameicon" />&nbsp;<a
                href="{{utility.utilityURL}}" target="_blank">{{utility.utilityTitle}}</a>&nbsp;<a
                href="{{utility.utilityGitHub}}" target="_blank">
                <fa-icon [icon]="faGitHub" style="font-size: smaller;"></fa-icon>
            </a>
            &nbsp;
        </div>
        <div>
            <img src="/assets/universal_2_icon.png" width="20px">&nbsp; Universal 2 for <strong class="dashed"
                ngbTooltip="Macs with M1 or later processors. Manufactured since 2020.">Apple
                Silicon</strong>
            and <strong class="dashed" ngbTooltip="Macs with 64-bit Intel processors. Manufactured since 2008.">64-Bit
                Intel</strong>
            Macs
            &nbsp;<div class="badge bg-info" style="height: 20px;">Signed
            </div>&nbsp;<div class="badge bg-success" style="height: 20px;">Notarized
            </div>&nbsp;<div class="badge bg-dark" *ngIf="utility.universal2MSPBuild" style="height: 20px;">
                Mac Source Ports Build&nbsp;&nbsp;<a href="{{utility.macSourcePortsGitHub}}" target="_new"><fa-icon
                        [icon]="faGitHub"></fa-icon></a>
            </div>
            <div class="badge bg-light" *ngIf="!utility.universal2MSPBuild" style="height: 20px;">
                Third Party Build
            </div><br><span style="font-size: smaller;">Build Date: {{utility.buildDate}}</span>
            <br>
            <fa-icon [icon]="faDownload"></fa-icon>&nbsp; <strong><a
                    href="{{utility.downloadUrl}}">Download</a></strong>
        </div>
    </div>
</div>
<br>
<br>