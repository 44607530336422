import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GameInfo } from '../game-info.model';
import gamesJson from '../games.json';
import { SEOService } from '../common/seo.service';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent {

  games: GameInfo[] = [];
  tagname: string | undefined;

  constructor(private route: ActivatedRoute, private seo: SEOService) { }

  ngOnInit(): void {
    let allGames: GameInfo[] = gamesJson;
    allGames.sort((a, b) => {
      return a.gameTitle.localeCompare(b.gameTitle);
    })

    this.route.params.subscribe(
      params => {
        this.tagname = params['tagname']?.toString();
        this.games = allGames.filter(
          ((item: GameInfo) => item.tags?.includes(this.tagname!))
        )
        this.seo.setTitle("Mac Source Ports: " + this.tagname! + " Games for Apple Silicon and Intel Macs");
        this.seo.updateMeta("twitter:title", "Mac Source Ports: " + this.tagname! + " Games for Apple Silicon and Intel Macs");
        this.seo.updateMeta("twitter:image", "https://macsourceports.com/assets/msp_icon.png");
      }
    );
  }

}
