<h1>Build System Status</h1>

<div class="d-flex justify-content-between bd-highlight mb-3">
  <div class="p-2 bd-highlight">
  </div>
  <div class="p-2 bd-highlight">
    <br>
    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
      <input type="radio" class="btn-check" name="btnradio" id="btnradio1" value="all" autocomplete="off"
        [(ngModel)]="mode" (ngModelChange)="onChange($event)">
      <label class="btn btn-outline-light" for="btnradio1">All</label>
      <input type="radio" class="btn-check" name="btnradio" id="btnradio2" value="msp" autocomplete="off"
        [(ngModel)]="mode" (ngModelChange)="onChange($event)">
      <label class="btn btn-outline-light" for="btnradio2">Mac Source Ports Builds</label>
      <input type="radio" class="btn-check" name="btnradio" id="btnradio3" value="tpb" autocomplete="off"
        [(ngModel)]="mode" (ngModelChange)="onChange($event)">
      <label class="btn btn-outline-light" for="btnradio3">Third Party Builds</label>
    </div>
  </div>
  <div class="p-2 bd-highlight">
  </div>
</div>

<table class="table table-hover">
  <thead class="table-dark">
    <tr>
      <th scope="col">Name</th>
      <th scope="col">Version</th>
      <th scope="col">Bulld Date</th>
      <th scope="col">Latest Release</th>
      <th scope="col">Last Commit</th>
      <th scope="col">Up to Date</th>
    </tr>
  </thead>
  <tbody>
    <tr [ngClass]="{'table-dark': !item.MSPBuild}" *ngFor="let item of filteredDashboardItems">
      <td><strong>{{item.sourcePortTitle}} ({{item.sourcePortType}})</strong>
        <br>
        <a href="{{item.sourcePortURL}}" target="_new">homepage</a>
        <span *ngIf="item.sourcePortGitHub">&nbsp;|&nbsp;<a href="{{item.sourcePortGitHub}}"
            target="_new">source</a></span>
        <span *ngIf="item.MSPGitHub">&nbsp;|&nbsp;<a href="{{item.MSPGitHub}}" target="_new">MSP Fork</a></span>
        <span *ngIf="item.MSPBuild && !item.MSPGitHub">&nbsp;|&nbsp;<a
            href="https://github.com/MacSourcePorts/MSPBuildSystem/releases/" target="_blank">MSP Build
            System</a></span>
        <span *ngIf="item.MSPBuild">&nbsp;|&nbsp;<a
            href="https://github.com/MacSourcePorts/MSPBuildSystem/wiki/{{item.sourcePortStub}}"
            target="_blank">wiki</a></span>
      </td>
      <td>{{item.buildVersion}}</td>
      <td>{{item.buildDate | date:'MMMM d, yyyy'}}</td>
      <td><span *ngIf="item.lastReleaseTag"><a href="{{item.lastReleaseURL}}"
            target="_new">{{item.lastReleaseName}}</a><br />{{item.lastReleaseTag}}&nbsp;|&nbsp;{{item.lastReleasePublished
          | date:'MMMM d, yyyy'}}</span></td>
      <td>{{item.sourcePortGitHubLastPush | date:'MMMM d, yyyy'}}</td>
      <td
        [ngClass]="{'table-success': item.upToDate === 'Yes', 'table-primary': item.upToDate === 'No', 'table-danger': item.upToDate === 'Maybe' }">
        {{item.upToDate}}&nbsp;<br /><a style="text-decoration: underline; cursor: pointer;"
          (click)="openModal(item)">update</a></td>
    </tr>
  </tbody>
</table>
<ng-template #codeModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">{{currentItem.sourcePortTitle}} {{currentItem.lastReleaseTag}}</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      Version: <input type="text" [ngModel]="currentItem.lastReleaseTag" (ngModelChange)="onVersionChange($event)">
      Version: <input type="text" [(ngModel)]="releaseTitle">
      <br />
      <br />
      <code>
        <textarea style="width: 100%; height: 70%;">{{gamesText}}</textarea>
      </code>
      <code>
        <textarea style="width: 100%; height: 50%;" rows="10">{{newsText}}</textarea>
      </code>
      <code>
        <textarea style="width: 100%; height: 50%;" rows="10">{{twitterUpdate}}</textarea>
      </code>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
    </div>
  </div>
</ng-template>