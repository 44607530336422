import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UtilityInfo } from '../utility-info.model';
import utilitiesJson from '../utilities.json';
import { SEOService } from '../common/seo.service';

@Component({
  selector: 'app-utility',
  templateUrl: './utility.component.html',
  styleUrls: ['./utility.component.scss']
})
export class UtilityComponent implements OnInit {

  utilityName: string | undefined;
  utilities: UtilityInfo[] = [];
  utility: UtilityInfo | undefined;

  constructor(private route: ActivatedRoute, private seo: SEOService) { }

  ngOnInit(): void {
    this.utilityName = this.route.snapshot.paramMap.get("utilityname")?.toString();
    this.utilities = utilitiesJson;
    this.utility = this.utilities.find(x => x.stubName === this.utilityName);
    this.seo.setTitle("Mac Source Ports: " + this.utility!.utilityTitle + " for Apple Silicon and Intel Macs");
    this.seo.updateMeta("twitter:title", "Mac Source Ports: " + this.utility!.utilityTitle + " for Apple Silicon and Intel Macs");
    this.seo.updateMeta("twitter:image", "https://macsourceports.com" + this.utility!.appIcon);
  }

}
