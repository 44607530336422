<span class="d-none d-lg-block">Showing {{startItem + 1}}-{{endItem}} of {{gameCount}} games<br><br></span>
<game-info *ngFor="let game of games" [game]="game"></game-info>
<div class="d-none d-md-block">
    <div class="d-flex justify-content-center">
        <ul class="pagination pagination-lg">
            <li class="page-item" [ngClass]="{'disabled' : !previousPage}">
                <a class="page-link" [routerLink]="['/games']" [queryParams]="{page: previousPage, sort: sortMode}" (click)="onClick($event)">&laquo;</a>
            </li>
            <li *ngFor="let pageitem of pages" class="page-item" [ngClass]="{'active' : pageitem == page}">
                <a class="page-link" [routerLink]="['/games']" [queryParams]="{page: pageitem, sort: sortMode}" (click)="onClick($event)">{{pageitem}}</a>
            </li>
            <li class="page-item" [ngClass]="{'disabled' : !nextPage}">
                <a class="page-link" [routerLink]="['/games']" [queryParams]="{page: nextPage, sort: sortMode}" (click)="onClick($event)">&raquo;</a>
            </li>
        </ul>
    </div>
</div>

<div class="d-block d-md-none">
    <div class="d-flex justify-content-center">
        <ul class="pagination pagination-sm">
            <li class="page-item" [ngClass]="{'disabled' : !previousPage}">
                <a class="page-link" [routerLink]="['/games']" [queryParams]="{page: previousPage, sort: sortMode}" (click)="onClick($event)">&laquo;</a>
            </li>
            <li *ngFor="let pageitem of pages" class="page-item" [ngClass]="{'active' : pageitem == page}">
                <a class="page-link" [routerLink]="['/games']" [queryParams]="{page: pageitem, sort: sortMode}" (click)="onClick($event)">{{pageitem}}</a>
            </li>
            <li class="page-item" [ngClass]="{'disabled' : !nextPage}">
                <a class="page-link" [routerLink]="['/games']" [queryParams]="{page: nextPage, sort: sortMode}" (click)="onClick($event)">&raquo;</a>
            </li>
        </ul>
    </div>
</div>