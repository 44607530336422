import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Utils from '../common/utils';
import { GameInfo } from '../game-info.model';
import gamesJson from '../games.json';
import { SEOService } from '../common/seo.service';

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss']
})
export class GamesComponent implements OnInit {

  games: GameInfo[] = [];
  page: number = 1;
  startItem: number = 1;
  endItem: number = 1;
  gameCount: number = 1;
  sortMode: number = 1;
  previousPage: number | undefined;
  nextPage: number | undefined;
  pages: number[] = [];
  
  constructor(private route: ActivatedRoute, private seo: SEOService) { }

  ngOnInit(): void {
    this.seo.setTitle("Mac Source Ports: Games for Apple Silicon and Intel Macs");
    this.seo.updateMeta("twitter:title", "Mac Source Ports: Games for Apple Silicon and Intel Macs");
    this.seo.updateMeta("twitter:image", "https://macsourceports.com/assets/msp_icon.png");
    let allGames = gamesJson;
    this.gameCount = allGames.length;

    this.route.queryParams.subscribe(params => {

      // this is duplicated on the Home page
      var pageString = params['page']
      var sortString = params['sort']

      let pageSize = 10;
      this.page = pageString ? +pageString : 1;
      this.sortMode = sortString ? +sortString : 1;
      this.startItem = (this.page - 1) * pageSize;
      this.endItem = ((+this.page! - 1) * pageSize) + pageSize;  
      this.previousPage = this.page > 1 ? this.page - 1 : undefined;      
      this.nextPage = (this.endItem < allGames.length) ? this.page + 1 : undefined;

      // this is probably so stupid...
      var startItemCounter = 0;
      var pageItemCounter = 1;
      this.pages = [];
      while (startItemCounter < allGames.length) {
        this.pages.push(pageItemCounter++);
        startItemCounter = startItemCounter + pageSize;
      }
      this.games = Utils.sortGames(allGames, this.sortMode);
      this.games = this.games.slice(this.startItem, this.endItem);
      if (this.endItem > allGames.length) {
        this.endItem = allGames.length;
      }
    });

    var pageString = this.route.snapshot.queryParamMap.get('page');
  }

  onClick(event: any) {

    console.log("onClick");

    window.scroll({ 
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
     }); 
 }

}
