<h1>News</h1>

<div *ngFor="let newsItem of news">
    <div class="card mb-3">
        <h3 class="card-header">
            <div class="row">
                <div class="col-12 col-md-8 align-middle text-left">
                  <h3><a style="text-decoration: none;" href="{{newsItem.link}}"><img *ngIf="newsItem.icon"
                        src="{{newsItem.icon}}" width="64" height="64" class="gameicon" />
                      {{newsItem.title}}</a></h3>
                </div>
            </div>          
        </h3>        
        <div class="card-body">
          <p class="card-text" [innerHTML]="newsItem.content"></p>
        </div>
      </div>      
    <br>
</div>