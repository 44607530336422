import { SourcePortInfo } from "./sourceport-info.model";

export class GameInfo {
    gameTitle!: string;
    stubName!: string;
    appIcon!: string;
    screenshot: string | undefined;
    developer: string | undefined;
    releaseDate: string | undefined;
    sourceReleaseDate: string | undefined;
    description: string | undefined;
    goglink: string | undefined;
    humblelink: string | undefined;
    sourcePorts: SourcePortInfo[] = [];
    addedToMacSourcePorts!: Date;
    tags: string[] | undefined;
    lastUpdated!: Date;
}
