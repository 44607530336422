import { Component, OnInit } from '@angular/core';
import { SourcePortInfo } from '../sourceport-info.model';
import { GameInfo } from '../game-info.model';
import gamesJson from '../games.json';
import Utils from '../common/utils';
import { SEOService } from '../common/seo.service';

@Component({
  selector: 'app-sourceports',
  templateUrl: './sourceports.component.html',
  styleUrls: ['./sourceports.component.scss']
})
export class SourcePortsComponent implements OnInit {

  sourcePorts: SourcePortInfo[] = [];
  games: GameInfo[] = [];
  sourcePortDict: Record<string, SourcePortInfo> = {};

  constructor(private seo: SEOService) { }

  ngOnInit(): void {
    this.seo.setTitle("Mac Source Ports: Source Ports for Apple Silicon and Intel Macs");
    this.seo.updateMeta("twitter:title", "Mac Source Ports: Source Ports for Apple Silicon and Intel Macs");
    this.seo.updateMeta("twitter:image", "https://macsourceports.com/assets/msp_icon.png");
    this.games = gamesJson;
    this.sourcePorts = Utils.getSourcePorts(this.games);
  }

}