import { Component, Input, OnInit } from '@angular/core';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faDownload, faFileContract, faStamp, faYinYang } from '@fortawesome/free-solid-svg-icons';
import { GameInfo } from '../game-info.model';
import { SourcePortInfo } from '../sourceport-info.model';

@Component({
  selector: 'game-info',
  templateUrl: './game-info.component.html',
  styleUrls: ['./game-info.component.scss']
})
export class GameInfoComponent implements OnInit {

  @Input() game!: GameInfo;

  faDownload = faDownload;
  faQuestionCircle = faQuestionCircle;
  faFileContract = faFileContract;
  faStamp = faStamp;
  faGitHub = faGithub;
  faYinYang = faYinYang;

  instructionsCollapsed = true;

  constructor() { }

  ngOnInit(): void {
  }

  toggleInstructions(sourcePort: SourcePortInfo) {
    sourcePort.instructionsCollapsed = !sourcePort.instructionsCollapsed;
  }

  onClick(event: any) {

    console.log("onClick");

    window.scroll({ 
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
     }); 
   }

}
