export class DashboardItem {
    sourcePortTitle!: string;
    sourcePortType!: string;
    sourcePortStub!: string;
    buildVersion!: string;
    downloadURL!: string;
    buildDate!: Date;
    sourcePortGitHub!: string;
    sourcePortGitHubLastPush!: Date;
    MSPGitHub: string | undefined;
    lastReleaseTag!: string;
    lastReleaseName!: string;
    lastReleasePublished!: Date;
    lastReleaseURL!: string;
    upToDate!: string;
    sourcePortURL!: string;
    MSPBuild: boolean = false;
}
