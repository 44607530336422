<div class="page-header">
  <div class="row">
    <div class="col-auto">
      <img src="/assets/msp_icon.png" width="128">
    </div>
    <div class="col-auto" style="vertical-align:middle;">
      <br>
      <h1>Mac Source Ports</h1>
      <h5>Run old games on new Macs</h5>
    </div>
  </div>
</div>
<br>
<p><strong>Mac Source Ports</strong> features native app builds of source ports of
  your favorite games for both Apple Silicon and Intel Macs, <span class="dashed"
    ngbTooltip="Digitally signed executables to run on macOS with Gatekeeper" placement="bottom">signed</span> and <span
    class="dashed" ngbTooltip="Submitted and notarized by Apple to be free from malware"
    placement="bottom">notarized</span> whenever possible.</p>
<div class="d-flex justify-content-between bd-highlight mb-3">
  <div class="p-2 bd-highlight">
    <span class="d-none d-lg-block"><br></span>
    <input type="text" class="form-control d-none d-lg-block" placeholder="Filter..." [(ngModel)]="searchText"
      (ngModelChange)="onChange($event)" style="width: 250px;" [hidden]="mode != 'games'">
  </div>
  <div class="p-2 bd-highlight">
    <br>
    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
      <input type="radio" class="btn-check" name="btnradio" id="btnradio1" value="games" autocomplete="off"
        [(ngModel)]="mode">
      <label class="btn btn-outline-light" for="btnradio1">Games</label>
      <input type="radio" class="btn-check" name="btnradio" id="btnradio2" value="sourcePorts" autocomplete="off"
        [(ngModel)]="mode">
      <label class="btn btn-outline-light" for="btnradio2">Ports</label>
      <input type="radio" class="btn-check" name="btnradio" id="btnradio3" value="utilities" autocomplete="off"
        [(ngModel)]="mode">
      <label class="btn btn-outline-light" for="btnradio3">Utilities</label>
    </div>
  </div>
  <div class="p-2 bd-highlight">
    <span class="d-none d-lg-block" [hidden]="mode != 'games'">Sort By: </span><select
      class="form-select d-none d-lg-block" style="width: 255px;" [hidden]="mode != 'games'" [(ngModel)]="sortMode"
      (ngModelChange)="onChange($event)">
      <option value="5">Last Updated</option>
      <option value="1">Date Added</option>
      <option value="2">Name</option>
      <option value="3">Oldest Release Date</option>
      <option value="4">Newest Release Date</option>
    </select>
  </div>
</div>
<span [hidden]="mode != 'games'" *ngIf="gameCount !== allGames.length" class="d-none d-lg-block">Showing {{gameCount}}
  of {{allGames.length}} games<br><br></span>
<span [hidden]="mode != 'games'" *ngIf="gameCount === allGames.length" class="d-none d-lg-block">Showing {{startItem +
  1}}-{{endItem}} of {{gameCount}} games<br><br></span>
<div class="row d-flex justify-content-center d-lg-none" style="padding-bottom: 20px;">
  <input type="text" placeholder="Filter..." class="form-control col-md-6" [(ngModel)]="searchText"
    (ngModelChange)="onChange($event)" style="width: 250px;" [hidden]="mode != 'games'">
</div>
<game-info [hidden]="mode != 'games'" *ngFor="let game of games | filterGames : searchText as result" [game]="game">
</game-info>
<div [hidden]="mode != 'games'" *ngIf="gameCount === allGames.length" class="d-none d-md-block">
  <div class="d-flex justify-content-center">
    <ul class="pagination pagination-lg">
      <li class="page-item" [ngClass]="{'disabled' : !previousPage}">
        <a class="page-link" [routerLink]="['/games']" [queryParams]="{page: previousPage, sort: sortMode}">&laquo;</a>
      </li>
      <li *ngFor="let pageitem of pages" class="page-item" [ngClass]="{'active' : pageitem == page}">
        <a class="page-link" [routerLink]="['/games']" [queryParams]="{page: pageitem, sort: sortMode}">{{pageitem}}</a>
      </li>
      <li class="page-item" [ngClass]="{'disabled' : !nextPage}">
        <a class="page-link" [routerLink]="['/games']" [queryParams]="{page: nextPage, sort: sortMode}">&raquo;</a>
      </li>
    </ul>
  </div>
</div>
<div [hidden]="mode != 'games'" *ngIf="gameCount === allGames.length" class="d-block d-md-none">
  <div class="d-flex justify-content-center">
    <ul class="pagination pagination-sm">
      <li class="page-item" [ngClass]="{'disabled' : !previousPage}">
        <a class="page-link" [routerLink]="['/games']" [queryParams]="{page: previousPage, sort: sortMode}">&laquo;</a>
      </li>
      <li *ngFor="let pageitem of pages" class="page-item" [ngClass]="{'active' : pageitem == page}">
        <a class="page-link" [routerLink]="['/games']" [queryParams]="{page: pageitem, sort: sortMode}">{{pageitem}}</a>
      </li>
      <li class="page-item" [ngClass]="{'disabled' : !nextPage}">
        <a class="page-link" [routerLink]="['/games']" [queryParams]="{page: nextPage, sort: sortMode}">&raquo;</a>
      </li>
    </ul>
  </div>
</div>
<sourceport-info [hidden]="mode != 'sourcePorts'" *ngFor="let sourcePort of sourcePorts" [sourcePort]="sourcePort">
</sourceport-info>
<utility-info [hidden]="mode != 'utilities'" *ngFor="let utility of utilities" [utility]="utility"></utility-info>