import { DashboardItem } from "../dashboard-item.model";
import { GameInfo } from "../game-info.model";
import { SourcePortInfo } from "../sourceport-info.model";

export default class Utils {

  // NOTE: any changes to this "search for the unique source ports" routine
  //       need to be done to the mspSourcePortRoutesPlugin method in scully.MacSourcePorts.config.ts
  static getSourcePorts(games: GameInfo[]) {
    var sourcePorts: SourcePortInfo[] = [];
    var sourcePortDict: Record<string, SourcePortInfo> = {};

    games.forEach(game => {

      game.sourcePorts.forEach(sourcePort => {
        var x = sourcePortDict[sourcePort.sourcePortStub]
        if (x === undefined) {
          sourcePortDict[sourcePort.sourcePortStub] = sourcePort
          sourcePortDict[sourcePort.sourcePortStub].games = []
        }
        var tempGame = new GameInfo();
        tempGame.gameTitle = game.gameTitle;
        tempGame.appIcon = game.appIcon;
        tempGame.stubName = game.stubName;
        sourcePortDict[sourcePort.sourcePortStub].games!.push(tempGame);
      });

    });

    for (const key in sourcePortDict) {
      sourcePorts.push(sourcePortDict[key]);
    }

    sourcePorts.sort((a, b) => {
      return a.sourcePortTitle.localeCompare(b.sourcePortTitle);
    })


    return sourcePorts;
  }

  static getDashboardItems(games: GameInfo[], sourcePorts: SourcePortInfo[]) {

    var dashboardItems: DashboardItem[] = [];

    sourcePorts.forEach(sourcePort => {
      sourcePort.builds.forEach(build => {
        var item = new DashboardItem();
        item.sourcePortTitle = sourcePort.sourcePortTitle;
        item.sourcePortType = this.buildTypeToName(build.buildType);
        item.buildDate = new Date(build.buildDate!);
        item.buildDate.setHours(0, 0, 0, 0);
        item.buildVersion = build.version!;
        item.downloadURL = build.downloadURL!;
        item.sourcePortGitHub = sourcePort.sourcePortGitHub!;
        item.sourcePortStub = sourcePort.sourcePortStub;
        item.sourcePortURL = sourcePort.sourcePortURL!;
        item.MSPGitHub = build.MSPGitHub;
        item.MSPBuild = build.MSPBuild;
        dashboardItems.push(item);
      })
    })

    return dashboardItems;
  }

 static buildTypeToName(buildType: number) {

    var buildTypeName = "";

    switch (+buildType) {
      case 1:
        buildTypeName = "Universal 2";
        break;
      case 2:
        buildTypeName = "Universal 1";
        break;
      case 3:
        buildTypeName = "Apple Silicon";
        break;
      case 4:
        buildTypeName = "Intel 64-bit";
        break;
      case 5:
        buildTypeName = "Intel 32-bit";
        break;
      case 6:
        buildTypeName = "PowerPC";
        break;
    }

    return buildTypeName;
  }

  static getGamesForSourcePort(games: GameInfo[], sourcePort: String) {
    var gamesForSourcePort: GameInfo[] = [];

    games.forEach(game => {
      game.sourcePorts.forEach(port => {
        if (port.sourcePortStub === sourcePort) {
          gamesForSourcePort.push(game);
        }
      });

    });

    return gamesForSourcePort;
  }

  static sortGames(games: GameInfo[], sortMode: number) {
    var sortedGames = games;

    switch (+sortMode) {
      case 1: {
        sortedGames.sort((a, b) => {
          return <any>new Date(b.addedToMacSourcePorts) - <any>new Date(a.addedToMacSourcePorts);
        })
        break;
      }
      case 2: {
        sortedGames.sort((a, b) => {
          return a.gameTitle.localeCompare(b.gameTitle);
        })
        break;
      }
      case 3: {
        sortedGames.sort((a, b) => {
          return <any>new Date(a.releaseDate!) - <any>new Date(b.releaseDate!);
        })
        break;
      }
      case 4: {
        sortedGames.sort((a, b) => {
          return <any>new Date(b.releaseDate!) - <any>new Date(a.releaseDate!);
        })
        break;
      }
      case 5: {
        sortedGames.sort((a, b) => {
          return <any>new Date(b.lastUpdated) - <any>new Date(a.lastUpdated);
        })
        break;
      }
    }

    return sortedGames;
  }
}