<h1>Frequently Asked Questions</h1>

<div *ngFor="let faq of faqs">
    <div class="card mb-3">
        <a name="{{faq.bookmark}}">&nbsp;</a>
        <h3 class="card-header" [innerHTML]="faq.question"></h3>
        <div class="card-body">
          <p class="card-text" [innerHTML]="faq.answer"></p>
        </div>
      </div>      
    <br>
</div>