import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { FaqComponent } from './faq/faq.component';
import { GameComponent } from './game/game.component';
import { GamesComponent } from './games/games.component';
import { HomeComponent } from './home/home.component';
import { RssComponent } from './rss/rss.component';
import { SourcePortComponent } from './sourceport/sourceport.component';
import { SourcePortsComponent } from './sourceports/sourceports.component';
import { TagComponent } from './tag/tag.component';
import { UtilitiesComponent } from './utilities/utilities.component';
import { UtilityComponent } from './utility/utility.component';
import { NewsComponent } from './news/news.component';
import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [
  { path: 'games', component: GamesComponent},
  { path: 'game/:gamename', component: GameComponent},
  { path: 'sourceports', component: SourcePortsComponent},
  { path: 'sourceport/:sourceportname', component: SourcePortComponent},
  { path: 'utilities', component: UtilitiesComponent},
  { path: 'utility/:utilityname', component: UtilityComponent},
  { path: 'tag/:tagname', component: TagComponent},
  { path: 'faq', component: FaqComponent},
  { path: 'about', component: AboutComponent},
  { path: 'rss2', component: RssComponent},
  { path: 'news', component: NewsComponent},
  { path: 'dashboard/:ghtoken', component: DashboardComponent},
  { path: '**', component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
