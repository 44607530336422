import { Component, OnInit } from '@angular/core';
import { FAQ } from 'src/faq.model';
import faqJson from '../faq.json';
import { SEOService } from '../common/seo.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  faqs: FAQ[] = [];

  constructor(private seo: SEOService) { }

  ngOnInit(): void {
    this.faqs = faqJson;
    this.seo.setTitle("Mac Source Ports: Frequently Asked Questions");
    this.seo.updateMeta("twitter:title", "Mac Source Ports: Frequently Asked Questions");
    this.seo.updateMeta("twitter:image", "https://macsourceports.com/assets/msp_icon.png");
  }

}
