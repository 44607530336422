<div class="card bg-secondary">
  <div class="card-header">
    <div class="row">
      <div class="col-12 col-md-8 align-middle text-left">
        <h3><a style="text-decoration: none;" [routerLink]="['/game', game.stubName]"><img *ngIf="game.appIcon"
              src="{{game.appIcon}}" width="64" height="64" class="gameicon" />
            {{game.gameTitle}}</a></h3>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col">
        <p>
          <strong>Developer:</strong>&nbsp;{{game.developer}}<br>
          <strong>Release Date:</strong>&nbsp;{{game.releaseDate}}<br>
          <strong>Source Code Release Date:</strong>&nbsp;{{game.sourceReleaseDate}}
        </p>
        <p [innerHTML]="game.description"></p>
        <div *ngIf="game.goglink">
          <a href="{{game.goglink}}">
            <img src="/assets/gog_logo.png" width="20" height="20" />
          </a>
          &nbsp;
          <a href="{{game.goglink}}" target="_blank">
            Buy <em>{{game.gameTitle}}</em> on GOG.com
          </a>
          <br>
        </div>
        <div *ngIf="game.humblelink">
          <a href="{{game.humblelink}}">
            <img src="/assets/humble_logo.png" width="20" height="20" />
          </a>
          &nbsp;
          <a href="{{game.humblelink}}" target="_blank">
            Buy <em>{{game.gameTitle}}</em> on the Humble Store
          </a>
        </div>
        <div *ngIf="game.goglink || game.humblelink">
          <span style="font-size: x-small;">Purchasing a game through one of our links helps support the
            site.&nbsp;&nbsp;<span *ngIf="game.goglink">You can use our <a
                href="https://macsourceports.com/utility/extractor"><i>Extractor</i></a>
              utility to get the data from the GOG game installer.</span></span>
          <br />
          <br />
        </div>
      </div>
      <div class="col-auto">
        <img *ngIf="game.screenshot" style="float: right;" src="{{game.screenshot}}" width="240" height="135" />
      </div>
    </div>
    <span style="font-size: larger; font-weight: bold;">Source Port<span *ngIf="game.sourcePorts.length > 1">s</span>:
    </span>
    <div *ngFor="let sourcePort of game.sourcePorts" class="align-middle">
      <div><img *ngIf="game.appIcon" src="{{sourcePort.sourcePortIcon}}" width="32" height="32"
          class="gameicon" />&nbsp;<a href="{{sourcePort.sourcePortURL}}"
          target="_blank">{{sourcePort.sourcePortTitle}}</a>&nbsp;<a *ngIf="sourcePort.sourcePortGitHub"
          href="{{sourcePort.sourcePortGitHub}}" target="_blank">
          <fa-icon [icon]="faGitHub" style="font-size: smaller;"></fa-icon>
        </a>
        &nbsp;
      </div>
      <build-info *ngFor="let build of sourcePort.builds" [build]="build"></build-info>
      <a style="font-size: smaller; text-decoration: underline; cursor: pointer;"
        (click)="toggleInstructions(sourcePort)">Installation
        instructions</a>
      <div style="font-size: smaller;" [hidden]="sourcePort.instructionsCollapsed"
        [innerHTML]="sourcePort.instructions"></div>
    </div>
  </div>
  <div class="card-footer">
    Tags: <span *ngFor="let tag of game.tags">
      <a [routerLink]="['/tag', tag]" class="alert-link" (click)="onClick($event)">{{tag}}</a>&nbsp;&nbsp;</span>
  </div>
</div>
<br>
<br>