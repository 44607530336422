<h1>About</h1>
Mac Source Ports is designed to feature signed and notarized versions of source ports for your favorite old games. My goal is to make everything be at least Universal 2 so it runs natively on Intel 64-bit Macs and Apple Silicon Macs. 
<br>
<br>
For info on what signed and notarized means, hit the FAQ.
<br>
<br>
This website and its contents is &copy; {{currentYear}} MacSourcePorts.com. Code for the source ports is property of their respective projects. All names, graphics and icons for games are copyrighted by their respective developers and publishers. Theme based on <a href="https://bootswatch.com/quartz/">Quartz by Bootswatch</a>.
<br>
<br>
