<div class="card bg-secondary">
    <div class="card-header">
        <div class="row">
            <div class="col-12 col-md-8 align-middle text-left">
                <h3><a style="text-decoration: none;" [routerLink]="['/sourceport', sourcePort.sourcePortStub]"><img
                            *ngIf="sourcePort.sourcePortIcon" src="{{sourcePort.sourcePortIcon}}" width="64" height="64"
                            class="gameicon" />
                        {{sourcePort.sourcePortTitle}}</a></h3>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col">
                <div>Homepage: <a href="{{sourcePort.sourcePortURL}}"
                        target="_blank">{{sourcePort.sourcePortURL}}</a>&nbsp;<a href="{{sourcePort.sourcePortGitHub}}"
                        target="_blank">
                        <fa-icon [icon]="faGitHub" style="font-size: smaller;"></fa-icon>
                    </a>
                    &nbsp;
                </div>
                <build-info *ngFor="let build of sourcePort.builds" [build]="build"></build-info>
            </div>
        </div>
        <br>
        <div style="font-size: larger; font-weight: bold;">Games:</div>
        <div *ngFor="let game of sourcePort.games" class="align-middle">
            <img *ngIf="game.appIcon" src="{{game.appIcon}}" width="32" height="32" class="gameicon" />&nbsp;<a
                [routerLink]="['/game', game.stubName]">{{game.gameTitle}}</a>
        </div>
    </div>
</div>
<br>
<br>