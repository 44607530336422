import { Component, OnInit } from '@angular/core';
import { SEOService } from '../common/seo.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  currentYear = new Date().getFullYear();

  constructor(private seo: SEOService) { }

  ngOnInit(): void {
    this.seo.setTitle("Mac Source Ports: About");
    this.seo.updateMeta("twitter:title", "Mac Source Ports: About");
    this.seo.updateMeta("twitter:image", "https://macsourceports.com/assets/msp_icon.png");
  }

}
