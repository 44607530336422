import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(private titleService: Title, private metaService: Meta) { }

  public setTitle(title: string) {
    this.titleService.setTitle(title)
  }

  public updateMeta(name: string, content: string) {
    this.metaService.updateTag({ name: name, content: content });
  }
}
