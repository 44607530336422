import { Component, OnInit } from '@angular/core';
import { Feed } from "feed";
import { GameInfo } from '../game-info.model';
import gamesJson from '../games.json';
import { NewsItem } from '../news-item.model';
import newsJson from '../news.json';
import Utils from '../common/utils';
import { SourcePortInfo } from '../sourceport-info.model';
import utilitiesJson from '../utilities.json';
import { UtilityInfo } from '../utility-info.model';

@Component({
  selector: 'app-rss',
  templateUrl: './rss.component.html',
  styleUrls: ['./rss.component.scss']
})
export class RssComponent implements OnInit {

  games: GameInfo[] = [];
  news: NewsItem[] = [];
  sourcePorts: SourcePortInfo[] = [];
  utilities: UtilityInfo[] = [];
  rss2: string = "";
  atom1: string = "";
  json1: string = "";
  sitemap: string[] = []
  sitemaptext: string = "";
  metatext: string = "";

  constructor() { }

  ngOnInit(): void {
    this.games = gamesJson;
    this.news = newsJson;
    this.utilities = utilitiesJson;

    const feed = new Feed({
      title: "Mac Source Ports",
      description: "Run old games on new Macs",
      id: "https://macsourceports.com/",
      link: "https://macsourceports.com/",
      language: "en", // optional, used only in RSS 2.0, possible values: http://www.w3.org/TR/REC-html40/struct/dirlang.html#langcodes
      image: "https://macsourceports.com/assets/msp_icon.png",
      favicon: "https://macsourceports.com/favicon.ico",
      copyright: "All rights reserved " + new Date().getFullYear() + ", Mac Source Ports",
      updated: new Date(), // optional, default = today
      // generator: "awesome", // optional, default = 'Feed for Node.js'
      feedLinks: {
        rss2: "https://macsourceports.com/rss",
        json: "https://macsourceports.com/feed.json",
        atom: "https://macsourceports.com/atom"
      },
      author: {
        name: "Tom Kidd",
        email: "tom@macsourceports.com"
      }
    });

    this.games.forEach(game => {
      feed.addItem({
        title: game.gameTitle,
        id: "https://macsourceports.com/game/" + game.stubName,
        link: "https://macsourceports.com/game/" + game.stubName,
        date: new Date(game.addedToMacSourcePorts),
        published: new Date(game.addedToMacSourcePorts),
        description: this.truncate(game.description!, 200, true),
        content: this.truncate(game.description!, 200, true),
        author: [
          {
            name: "Tom Kidd",
            email: "tom@macsourceports.com"
          }
        ],
        image: "https://macsourceports.com" + game.appIcon
      });
      this.sitemap.push("https://www.macsourceports.com/game/" + game.stubName + "/");
    });
    
    this.sourcePorts = Utils.getSourcePorts(this.games);

    this.sourcePorts.forEach(sourcePort => {
      this.sitemap.push("https://www.macsourceports.com/sourceport/" + sourcePort.sourcePortStub + "/");
    });

    this.utilities.forEach(utility => {
      this.sitemap.push("https://www.macsourceports.com/utility/" + utility.stubName + "/");
    });

    this.news.forEach(newsItem => {
      feed.addItem({
        title: newsItem.title,
        id: newsItem.date.toString(),
        link: newsItem.link,
        date: new Date(newsItem.date),
        published: new Date(newsItem.date),
        description: this.truncate(newsItem.content, 200, true),
        content: this.truncate(newsItem.content, 200, true),
        author: [
          {
            name: "Tom Kidd",
            email: "tom@macsourceports.com"
          }
        ],
        image: "https://macsourceports.com" + newsItem.icon
      });
    });

    feed.items.sort((a, b) => {
      return <any>new Date(b.date!) - <any>new Date(a.date!);
    });

    feed.addCategory("Gaming");

    this.rss2 = feed.rss2();
    this.atom1 = feed.atom1();
    this.json1 = feed.json1();
    this.sitemaptext = this.sitemap.join("\n");
    this.metatext = JSON.stringify(Utils.getDashboardItems(this.games, this.sourcePorts), null, 4);
  }

  truncate(str: string, n: number, useWordBoundary: boolean) {
    if (str.length <= n) { return str; }
    const subString = str.slice(0, n - 1); // the original check
    return (useWordBoundary
      ? subString.slice(0, subString.lastIndexOf(" "))
      : subString) + "&hellip;";
  };

  stripHtml(html: string) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

}
