import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GameInfo } from '../game-info.model';
import gamesJson from '../games.json';
import { SEOService } from '../common/seo.service';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit {

  gamename: string | undefined;
  games: GameInfo[] = [];
  game: GameInfo | undefined;

  constructor(private route: ActivatedRoute, private seo: SEOService) { }

  ngOnInit(): void {
    this.gamename = this.route.snapshot.paramMap.get("gamename")?.toString();
    this.games = gamesJson;
    this.game = this.games.find(x => x.stubName === this.gamename);
    this.seo.setTitle("Mac Source Ports: " + this.game!.gameTitle + " for Apple Silicon and Intel Macs");
    this.seo.updateMeta("twitter:title", "Mac Source Ports: " + this.game!.gameTitle);
    this.seo.updateMeta("twitter:image", "https://macsourceports.com" + this.game!.appIcon);
    this.seo.updateMeta("description", "Mac Source Ports: " + this.game!.gameTitle);
  }

}
