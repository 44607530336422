<p>rss2:</p>
<textarea style="font-family:Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace;" [(ngModel)]='rss2' rows="30" cols="120">                             
</textarea>
<p>atom1:</p>
<textarea style="font-family:Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace;" [(ngModel)]='atom1' rows="30" cols="120">                             
</textarea>
<p>json1:</p>
<textarea style="font-family:Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace;" [(ngModel)]='json1' rows="30" cols="120">                             
</textarea>
<p>sitemap:</p>
<textarea style="font-family:Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace;" [(ngModel)]='sitemaptext' rows="30" cols="120">
</textarea>
<p>meta:</p>
<textarea style="font-family:Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace;" [(ngModel)]='metatext' rows="30" cols="120">
</textarea>