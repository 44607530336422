import { Component, OnInit } from '@angular/core';
import { GameInfo } from '../game-info.model';
import gamesJson from '../games.json';
import utilitiesJson from '../utilities.json';
import { SourcePortInfo } from '../sourceport-info.model';
import { UtilityInfo } from '../utility-info.model';
import Utils from '../common/utils';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  games: GameInfo[] = [];
  allGames: GameInfo[] = [];
  sourcePorts: SourcePortInfo[] = [];
  sourcePortDict: Record<string, SourcePortInfo> = {};
  utilities: UtilityInfo[] = [];
  mode: string = "games";
  searchText = "";
  sortMode: number = 5;
  gameCount: number = 0;
  page: number = 1;
  startItem: number = 1;
  endItem: number = 1;
  previousPage: number | undefined;
  nextPage: number | undefined;
  pages: number[] = [];

  constructor() { }

  ngOnInit(): void {
    this.games = Utils.sortGames(gamesJson, this.sortMode);
    this.gameCount = this.games.filter(
      (item: GameInfo) => item.gameTitle.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
    ).length;
    this.sliceGames();
    this.sourcePorts = Utils.getSourcePorts(this.allGames);
    this.utilities = utilitiesJson;
  }

  sliceGames() {
    this.allGames = gamesJson;

    // this is duplicated on the Games page
    let pageSize = 10;
    this.startItem = (this.page - 1) * pageSize;
    this.endItem = ((+this.page! - 1) * pageSize) + pageSize;
    this.previousPage = this.page > 1 ? this.page - 1 : undefined;
    this.nextPage = (this.endItem < this.allGames.length) ? this.page + 1 : undefined;

    // this is probably so stupid...
    var startItemCounter = 0;
    var pageItemCounter = 1;
    this.pages = [];
    while (startItemCounter < this.allGames.length) {
      this.pages.push(pageItemCounter++);
      startItemCounter = startItemCounter + pageSize;
    }
    this.games = this.allGames.slice(this.startItem, this.endItem);
  }

  onChange(event: any) {
    this.games = gamesJson;
    this.gameCount = this.games.filter(
      (item: GameInfo) => item.gameTitle.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
    ).length;

    this.games = Utils.sortGames(this.games, this.sortMode);

    if (this.gameCount === this.games.length) {
      this.sliceGames();
    }
  }

}
