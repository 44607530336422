import { Component } from '@angular/core';
import { GameInfo } from '../game-info.model';
import gamesJson from '../games.json';
import { NewsItem } from '../news-item.model';
import newsJson from '../news.json';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent {

  news: NewsItem[] = [];

  ngOnInit(): void {
    this.news = newsJson;
  }

}
