import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GameInfoComponent } from './game-info/game-info.component';
import { HomeComponent } from './home/home.component';
import { GamesComponent } from './games/games.component';
import { FaqComponent } from './faq/faq.component';
import { AboutComponent } from './about/about.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';
import { GameComponent } from './game/game.component';
import { SourcePortInfoComponent } from './sourceport-info/sourceport-info.component';
import { SourcePortsComponent } from './sourceports/sourceports.component';
import { SourcePortComponent } from './sourceport/sourceport.component';
import { FilterGamesPipe } from './common/filterGames.pipe';
import { UtilityInfoComponent } from './utility-info/utility-info.component';
import { UtilityComponent } from './utility/utility.component';
import { UtilitiesComponent } from './utilities/utilities.component';
import { RssComponent } from './rss/rss.component';
import { BuildInfoComponent } from './build-info/build-info.component';
import { TagComponent } from './tag/tag.component';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { NewsComponent } from './news/news.component';
import { DashboardComponent } from './dashboard/dashboard.component';

@NgModule({
  declarations: [
    AppComponent,
    GameInfoComponent,
    HomeComponent,
    GamesComponent,
    FaqComponent,
    AboutComponent,
    GameComponent,
    SourcePortInfoComponent,
    SourcePortsComponent,
    SourcePortComponent,
    FilterGamesPipe,
    UtilityInfoComponent,
    UtilityComponent,
    UtilitiesComponent,
    RssComponent,
    BuildInfoComponent,
    TagComponent,
    NewsComponent,
    DashboardComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    FontAwesomeModule,
    NgxGoogleAnalyticsModule.forRoot('traking-code'),
    NgxGoogleAnalyticsModule.forRoot(environment.production ? "G-H7NLP6PS4B" : ""),
    NgxGoogleAnalyticsRouterModule,
    ScullyLibModule
  ],
  providers: [FilterGamesPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
