import { Component, OnInit } from '@angular/core';
import utilitiesJson from '../utilities.json';
import { UtilityInfo } from '../utility-info.model';
import { SEOService } from '../common/seo.service';

@Component({
  selector: 'app-utilities',
  templateUrl: './utilities.component.html',
  styleUrls: ['./utilities.component.scss']
})
export class UtilitiesComponent implements OnInit {

  utilities: UtilityInfo[] = [];
  
  constructor(private seo: SEOService) { }

  ngOnInit(): void {
    this.seo.setTitle("Mac Source Ports: Utilities for Apple Silicon and Intel Macs");
    this.seo.updateMeta("twitter:title", "Mac Source Ports: Utilities for Apple Silicon and Intel Macs");
    this.seo.updateMeta("twitter:image", "https://macsourceports.com/assets/msp_icon.png");
    this.utilities = utilitiesJson;
  }

}
