import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Utils from '../common/utils';
import { GameInfo } from '../game-info.model';
import gamesJson from '../games.json';
import { SourcePortInfo } from '../sourceport-info.model';
import { SEOService } from '../common/seo.service';

@Component({
  selector: 'app-port',
  templateUrl: './sourceport.component.html',
  styleUrls: ['./sourceport.component.scss']
})
export class SourcePortComponent implements OnInit {

  games: GameInfo[] = [];
  sourcePortName: string | undefined;
  sourcePorts: SourcePortInfo[] = [];
  sourcePort: SourcePortInfo | undefined;

  constructor(private route: ActivatedRoute, private seo: SEOService) { }

  ngOnInit(): void {
    this.sourcePortName = this.route.snapshot.paramMap.get("sourceportname")?.toString();
    this.games = gamesJson;
    this.sourcePorts = Utils.getSourcePorts(this.games);
    this.sourcePort = this.sourcePorts.find(x => x.sourcePortStub === this.sourcePortName);
    this.seo.setTitle("Mac Source Ports: " + this.sourcePort!.sourcePortTitle + " Source Port for Apple Silicon and Intel Macs");
    this.seo.updateMeta("twitter:title", "Mac Source Ports: " + this.sourcePort!.sourcePortTitle + " Source Port for Apple Silicon and Intel Macs");
    this.seo.updateMeta("twitter:image", "https://macsourceports.com" + this.sourcePort!.sourcePortIcon);
  }

}
